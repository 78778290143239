import Api from './Api'
import UploadApi from './UploadApi'


export default {
    // uploadFiles(body) {
    //     return Api().post('/api/dashuser/commonUploadFiles', body)
    // },

    uploadFiles(body) {
        return Api().post('admin/upload', body)
    },
    uploadExcel(body) {
        return Api().post('admin/uploadExcel', body)
    },
    uploadImages(body) {
        return UploadApi().post('admin/upload', body)
    },
}